import React from 'react';
import './App.css';
import {Route, Routes, useLocation} from 'react-router-dom';
import Header from './components/common/header/Header';
import Footer from './components/common/footer/Footer';
import Home from './components/home/Home';
import Wind from './components/wind/Wind';
import Battery from './components/battery/Battery';
import Hybrid from './components/hybrid/Hybrid';
import Contact from './components/contact/Contact';

/**
 * The main application component.
 *
 * This component serves as the entry point of the application,
 * rendering the header and providing links to learn more about React.
 *
 * @return {JSX.Element} The rendered component.
 */
function App() {
  const location = useLocation();
  const routeClass = location.pathname.substring(1) || 'home';


  return (
    <div className={`App ${routeClass}`}>
      <Header/>
      <div className="content">
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/wind" element={<Wind/>} />
          <Route path="/battery" element={<Battery/>} />
          <Route path="/hybrid" element={<Hybrid/>} />
          <Route path="/contact" element={<Contact/>} />
        </Routes>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
