import React from 'react';
import PropTypes from 'prop-types';
import './InfoBox.css';
import {useTranslation} from 'react-i18next';

const InfoBox = ({text, theme}) => {
  const {t, i18n} = useTranslation();
  const isDark = theme === 'dark';

  const boxStyle = isDark ? 'info-box-dark' : 'info-box-light';
  const textStyle = isDark ? 'info-text-dark' : 'info-text-light';

  let content;

  // Check if text is a string and translation exists
  if (typeof text === 'string' && i18n.exists(text)) {
    content = t(text);
  } else {
    content = text;
  }

  return (
    <div className={`box-common info-box ${boxStyle}`}>
      <div className={`text-common info-text ${textStyle}`}>
        {content}
      </div>
    </div>
  );
};

InfoBox.propTypes = {
  text: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

export default InfoBox;
