import React from 'react';
import {useLocation} from 'react-router-dom';
import {useScreenSize} from '../../../hooks/ScreenSizeContext';
import phoneIcon from '../../../assets/images/icons/phone_icon.svg';
import emailIcon from '../../../assets/images/icons/mail_icon.svg';
import {useTranslation} from 'react-i18next';
import './Footer.css';

const logoBig = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Flogos%2Flogo_big.webp?alt=media';
const bsdLogo = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Flogos%2Fpartner_bsd_logo.webp?alt=media';
const lexicTradeLogo = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Flogos%2Fpartner_lexic_trade_logo.webp?alt=media';

const Footer = () => {
  const location = useLocation();
  const isDesktop = useScreenSize();
  const {t} = useTranslation();

  const showFooter = !((location.pathname === '/hybrid' && !isDesktop) ||
  (location.pathname === '/' && isDesktop));

  const showExtraInfo = location.pathname === '/contact' && !isDesktop;

  return (
    <>
      {showFooter &&
      <footer className={`footer ${showExtraInfo ? 'extra-info' : 'default'}`}>
        {showExtraInfo ? (
          <>
            <div className="footer-row">
              <div className="footer-row-inner">
                <img src={phoneIcon} alt="Phone" />
                <p>+36302967206</p>
              </div>
            </div>
            <div className="footer-row">
              <div className="footer-row-inner">
                <img src={emailIcon} alt="Email" />
                <p>assal.sro2023@gmail.com</p>
              </div>
            </div>
          </>
        ) : null}
        {isDesktop ? <div className="footer-desktop">
          <div className="footer-text">
            Assal Ltd.<br/>
            2023<br/>
            Bartokova 32, Sturovo, 943 01<br/>
            {t('footer.desktop.slovakia')}
          </div>
          <div className="footer-logo">
            <img src={logoBig} alt="Company Logo"
              style={{width: '357px', height: '220px'}} />
          </div>
          <div className="footer-partner">
            <div className="partner-text">{t('footer.desktop.partners')}</div>
            <img src={lexicTradeLogo} alt="Lexic Trade Logo"
              style={{width: '80px', height: '80px'}} />
            <img src={bsdLogo} alt="BSD Logo"
              style={{width: '240px', height: '72.727px'}} />
          </div>
        </div> :
        <>
          <div className={`${showExtraInfo ? 'logo-container' : 'footer-logo'}`}>
            <img src={logoBig} alt="Company Logo" />
          </div>
        </>}
      </footer>}
    </>
  );
};

export default Footer;
