import React from 'react';
import TitleText from '../common/text_box/TitleText';
import './Hybrid.css';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from '../../hooks/ScreenSizeContext';

const Hybrid = () => {
  const {t} = useTranslation();
  const isDesktop = useScreenSize();

  return <div className="page-container">
    {isDesktop ?
    (<>
      <div className='hybrid-title-row'>
        <TitleText text={t('hybrid.title')}
          style={{width: '341px', alignSelf: 'start', textAlign: 'left'}} />
      </div>
      <div className="coming-soon-box">
        <div className="coming-soon-text">{t('hybrid.coming_soon')}</div>
        <div className="stay-tuned-text">{t('hybrid.stay_tuned')}</div>
      </div>
    </>) :
    (<>
      <div className="top-content" style={{height: 'calc(89vh)'}}>
        <div className="coming-soon-box" style={{marginTop: '15.5vh'}}>
          <div className="coming-soon-text">{t('hybrid.coming_soon')}</div>
          <div className="stay-tuned-text">{t('hybrid.stay_tuned')}</div>
        </div>
        <TitleText text={t('hybrid.title')} style={
          {marginBottom: '24vh', textAlign: 'left', height: '105px'}} />
      </div>
    </>)}
  </div>;
};

export default Hybrid;
