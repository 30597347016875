import React, {useEffect, useRef, useCallback} from 'react';
import {Link, useLocation} from 'react-router-dom';
import closeIcon from '../../../assets/images/icons/close_x.svg';
import hungary from '../../../assets/images/flags/hungary.svg';
import unitedKingdom from '../../../assets/images/flags/united_kingdom.svg';
import './Menu.css';
import {useTranslation} from 'react-i18next';

const Menu = ({onClose, isOpen}) => {
  const {t, i18n} = useTranslation();
  const location = useLocation();

  const routes = [
    {path: '/', label: 'menu.home'},
    {path: '/wind', label: 'menu.wind'},
    {path: '/battery', label: 'menu.battery'},
    {path: '/hybrid', label: 'menu.hybrid'},
    {path: '/contact', label: 'menu.contact'},
  ];

  const menuRef = useRef();

  const handleClickOutside = useCallback((event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onClose();
    }
  }, [onClose]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isOpen]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <nav className={`menu ${isOpen ? 'open' : ''}`} ref={menuRef}>
      <img src={closeIcon} alt="Close" className="close-icon"
        onClick={onClose} />
      <div className="menu-items">
        {routes.map((route) => (
          <Link
            key={route.path}
            to={route.path}
            className={`menu-item ${location.pathname === route.path ? 'active' : ''}`}
            onClick={(e) => {
              if (route.onClick) {
                e.preventDefault();
                route.onClick();
              }
              onClose();
            }}
          >
            {t(route.label)}
          </Link>
        ))}
        <div className='flags'>
          <img src={unitedKingdom} alt="English language"
            onClick={() => changeLanguage('en')} />
          <img src={hungary} alt="Hungarian language"
            onClick={() => changeLanguage('hu')} />
        </div>
      </div>
    </nav>
  );
};

export default Menu;
