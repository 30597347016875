import React, {useState} from 'react';
import {CSSTransition} from 'react-transition-group';
import {useLocation} from 'react-router-dom';
import hamburgerIcon from '../../../assets/images/icons/hamburger_menu.svg';
import Menu from '../menu/Menu';
import './Header.css';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`header ${location.pathname === '/' ? 'home' : ''}`}>
      <img src={hamburgerIcon} alt="Menu" className="menu-icon"
        onClick={toggleMenu} />
      <div className="logo"></div>
      <CSSTransition
        in={isMenuOpen}
        timeout={800}
        classNames="overlay"
        unmountOnExit
      >
        <div className="overlay"></div>
      </CSSTransition>
      <Menu onClose={toggleMenu} isOpen={isMenuOpen} />
    </header>
  );
};

export default Header;
