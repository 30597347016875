import React,
{createContext, useContext, useState, useEffect, useRef} from 'react';

const ScreenSizeContext = createContext();
const desktopScreenSize = 1024;

export const useScreenSize = () => {
  return useContext(ScreenSizeContext);
};

export const ScreenSizeProvider = ({children}) => {
  const [isDesktop, setIsDesktop] =
    useState(window.innerWidth >= desktopScreenSize);
  const previousWidth = useRef(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      if ((previousWidth.current < desktopScreenSize &&
        currentWidth >= desktopScreenSize) ||
       (previousWidth.current >= desktopScreenSize &&
        currentWidth < desktopScreenSize)) {
        setIsDesktop(currentWidth >= desktopScreenSize);
      }
      previousWidth.current = currentWidth;
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ScreenSizeContext.Provider value={isDesktop}>
      {children}
    </ScreenSizeContext.Provider>
  );
};
