import React from 'react';
import PropTypes from 'prop-types';
import './InfoBoxDesktop.css';
import {useTranslation} from 'react-i18next';

const InfoBoxDesktop = ({text, style}) => {
  const {t, i18n} = useTranslation();

  let content;

  // Check if text is a string and translation exists
  if (typeof text === 'string' && i18n.exists(text)) {
    content = t(text);
  } else {
    content = text;
  }

  const appliedStyle = style ? {
    ...style,
  } : {};

  return (
    <div className='box-desktop'>
      <div className='desc-desktop' style={appliedStyle}>
        {content}
      </div>
    </div>
  );
};

InfoBoxDesktop.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default InfoBoxDesktop;
