import React from 'react';
import ReactPlayer from 'react-player';
import {useNavigate} from 'react-router-dom';
import './Home.css';
import Button from '../common/button/Button';
import InfoBox from '../common/text_box/InfoBox';
import Slideshow from '../common/slideshow/Slideshow';
import TitleText from '../common/text_box/TitleText';
import {scrollToElement} from '../../utils/Utils';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from '../../hooks/ScreenSizeContext';

const introVideo = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fvideos%2Fhighlights.mp4?alt=media';
const plantGray = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fplant_2_grayed.webp?alt=media';

const solarPanel1 = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fwind_bg.webp?alt=media';
const windTurbine1 = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fwind_turbin_1.webp?alt=media';
const batteryBg = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fbattery_bg.webp?alt=media';
const hybridBg = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fhybrid_bg.webp?alt=media';
const plant1 = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fplant_1.webp?alt=media';
const plant2 = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fplant_2.webp?alt=media';
const plant3 = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fplant_3.webp?alt=media';
const plant4 = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fplant_4.webp?alt=media';
const plant5 = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fplant_5.webp?alt=media';

const Home = () => {
  const {t} = useTranslation();
  const isDesktop = useScreenSize();

  const images = [
    batteryBg,
    hybridBg,
    windTurbine1,
    solarPanel1,
    plant2,
    plant3,
    plant1,
    plant4,
    plant5,
  ];

  const navigate = useNavigate();

  return <div className="page-container">
    {isDesktop ? (<>
      <Slideshow images={images} />
      <div className="info-text-button-container">
        <InfoBox text={'home.desktop.info'} theme={'dark'} />
        <div className="text-button-container">
          <div className="home-text">
            {t('home.desktop.create_future_together')}
          </div>
          <Button
            text={t('home.contact_button')}
            style={{
              backgroundColor: 'var(--Grey-500)',
              hoverBackgroundColor: 'var(--Primary-1)',
            }}
            onClick={() => navigate('/contact')}
          />
        </div>
      </div>
    </>) :
    (<>
      <div className="top-content">
        <div className="home-text">{t('home.create_future')}</div>
        <TitleText text={t('home.together')} style={{marginBottom: '13vh'}} />
        <div className="buttons-container">
          <Button text={t('home.about_us_button')}
            onClick={() => scrollToElement('aboutUs', 62)}
          />
          <Button
            text={t('home.contact_button')}
            style={{
              backgroundColor: 'var(--Grey-500)',
              hoverBackgroundColor: 'var(--Primary-1)',
            }}
            onClick={() => navigate('/contact')}
          />
        </div>
      </div>
      <div id="aboutUs" className="about-us-text">
        {t('home.about_us_title')}
      </div>
      <InfoBox text={'home.info_1'} theme={'dark'} />
      <Slideshow images={images} />
      <InfoBox text={'home.info_2'} theme={'dark'}/>
    </>)
    }
    <div className='player-wrapper'>
      <ReactPlayer
        className='react-player'
        url={introVideo}
        width='100%'
        height='100%'
        controls={true}
        light={plantGray}
      />
    </div>
  </div>;
};

export default Home;
