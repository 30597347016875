/* eslint-disable max-len */
import React from 'react';
import TitleText from '../common/text_box/TitleText';
import Button from '../common/button/Button';
import InfoBox from '../common/text_box/InfoBox';
import InfoBoxDesktop from '../common/text_box/InfoBoxDesktop';
import TextSlideshow from '../common/slideshow/TextSlideshow';
import TextOnlySlideshow from '../common/slideshow/TextOnlySlideshow';
import './Wind.css';
import {scrollToElement} from '../../utils/Utils';
import {useTranslation} from 'react-i18next';
import {vertigoCards, indiaCards} from './Cards';
import {useScreenSize} from '../../hooks/ScreenSizeContext';


const Wind = () => {
  const {t} = useTranslation();
  const isDesktop = useScreenSize();

  const handleButtonClick = () => {
    scrollToElement('vertigo', 62);
  };

  return <div className="page-container">
    {isDesktop ?
    (<>
      <div id="vertigo" className="chapter-title" style={
        {alignSelf: 'center', width: '100%', maxWidth: '1530px'}}>
        {t('wind.vertigo_title')}
      </div>
      <div className='info-box-vertigo-text'>
        <InfoBoxDesktop text={'wind.vertigo_text'} />
      </div>
      <TextSlideshow items={vertigoCards} />
      <div className="chapter-title" style={
        {alignSelf: 'center', width: '100%', maxWidth: '1530px', marginTop: '15px'}}>
        {t('wind.india_title')}
      </div>
      <div className='india-content'>
        <InfoBoxDesktop text={'wind.india_text'} />
        <TextOnlySlideshow items={indiaCards} />
      </div>
    </>) :
    (<>
      <div className="top-content">
        <TitleText text={t('wind.title')} style={
          {marginTop: '44vh', textAlign: 'left', height: '105px'}} />
        <Button
          text={t('wind.learn_more')}
          onClick={handleButtonClick}
          style={{marginBottom: '10vh', alignSelf: 'end'}}
        />
      </div>
      <div id="vertigo" className="chapter-title" style={
        {alignSelf: 'center'}}>
        {t('wind.vertigo_title')}
      </div>
      <InfoBox text={'wind.vertigo_text'} theme="light" />
      <TextSlideshow items={vertigoCards} />
      <div className="chapter-title" style={
        {alignSelf: 'center', textAlign: 'left', marginTop: '15px'}}>
        {t('wind.india_title')}
      </div>
      <InfoBox text={'wind.india_text'} theme="light" />
      <TextOnlySlideshow items={indiaCards} />
    </>) }
  </div>;
};

export default Wind;
