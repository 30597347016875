import React from 'react';
import PropTypes from 'prop-types';
import './InfoBoxInterFont.css';
import {useTranslation} from 'react-i18next';

const InfoBoxInterFont = ({title, description}) => {
  const {t} = useTranslation();

  return (
    <div className="info-box-inter-font">
      <div className="info-title-inter-font">
        {t(title)}
      </div>
      <div className="info-description-inter-font">
        {t(description)}
      </div>
    </div>
  );
};

InfoBoxInterFont.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default InfoBoxInterFont;
