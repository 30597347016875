import React, {useState, useEffect, useRef} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './Message.css';
import Button from '../common/button/Button';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from '../../hooks/ScreenSizeContext';

const yellowFlowersBg = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fyellow_flowers_bg.webp?alt=media';

const BackIconDesktop = (
  <svg width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="back icon">
      <path id="back icon_2"
        // eslint-disable-next-line max-len
        d="M30 10.2903V14.7097H6.89481L12.736 21.8756L10.1873 25L0 12.5L10.1873 0L12.736 3.12445L6.89481 10.2903H30Z"
        fill="black" />
    </g>
  </svg>
);

const BackIconMobile = (
  <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="back icon"
      // eslint-disable-next-line max-len
      d="M25 8.23228V11.7677H5.74568L10.6133 17.5004L8.48943 20L0 10L8.48943 0L10.6133 2.49956L5.74568 8.23228H25Z"
      fill="black" />
  </svg>
);

// Simple email validation regex
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Message = ({onClose}) => {
  const {t} = useTranslation();
  const isDesktop = useScreenSize();
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [fieldsFilledOut, setFieldsFilledOut] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailSent, setEmailSent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const recaptchaInstance = useRef();

  const handleClose = () => {
    onClose();
  };

  const handleSendMessage = () => {
    setIsLoading(true);
  };

  const handleVerify = (response) => {
    if (response) {
      const recaptchaToken = recaptchaInstance.current.getValue();

      fetch('https://europe-central2-assal-green-energy.cloudfunctions.net/sendEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email, phoneNumber, message, recaptchaToken}),
      })
          .then((response) => response.json())
          .then((data) => {
            console.log('Success:', data);
            setEmailSent(true);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error('Error:', error);
            setEmailSent(false);
            setIsLoading(false);
          });
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsEmailValid(emailRegex.test(newEmail) || newEmail === '');
  };

  useEffect(() => {
    setFieldsFilledOut(email !== '' && message !== '');
  }, [email, message, isEmailValid]);

  return (
    <div
      className="message-container"
      style={{backgroundImage: `url(${yellowFlowersBg})`}}
    >
      <div
        className="back-icon"
        onClick={handleClose}
      >
        {isDesktop ? BackIconDesktop : BackIconMobile}
      </div>
      {isLoading ? (
        <div className="message-box">
          <p className="message-text">{t('message.loading')}</p>
          <ReCAPTCHA
            ref={recaptchaInstance}
            sitekey="6Lc0wtMoAAAAAOnDT8l9SzJ7TeBe8LZ8nt-d_p4V"
            onChange={handleVerify}
          />
        </div>
      ) : emailSent === null ? (
      <div className="message-box">
        <div className="inputs-container">
          <p className="message-text"
            style={{margin: 'auto', width: '100%', textAlign: 'center'}}>
            {t('message.leave_us_a_message')}
          </p>
          <div className={`mandatory-wrapper ${!isEmailValid ? 'invalid-email' : ''}`}>
            <input
              className="input-field"
              placeholder={t('message.your_email_address')}
              type="email"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          <input className="input-field"
            placeholder={t('message.your_phone_number')}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            type="tel" />
          <div className="mandatory-wrapper">
            <textarea className="input-textarea mandatory-field"
              placeholder={t('message.your_message')}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
        <p className="mandatory-note">{t('message.mandatory_fields')}</p>
        <Button
          text={t('message.send')}
          onClick={handleSendMessage}
          disabled={!fieldsFilledOut}/>
      </div>
      ) : emailSent ? (
        <div className="success-message">
          <div className="message-box">
            <p className="message-text"
              style={{margin: 'auto', width: '100%', textAlign: 'center'}}>
              {t('message.success')}</p></div>
        </div>
      ) : (
        <div className="failure-message">
          <div className="message-box">
            <p className="message-text"
              style={{margin: 'auto', width: '100%', textAlign: 'center'}}>
              {t('message.error')}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Message;
