import React from 'react';
import PropTypes from 'prop-types';
import './AdvantagesBox.css';
import {useTranslation} from 'react-i18next';

const AdvantagesBox = ({isMirrored, circleText, textItems}) => {
  const {t} = useTranslation();
  const containerClass = isMirrored ?
  'advantages-box mirrored' : 'advantages-box';

  return (
    <div className={containerClass}>
      <div className="circle-container">
        <div className="circle">
          <div className="circle-text">{t(circleText)}</div>
        </div>
      </div>
      <div className="texts-container">
        {textItems.map((item, index) => (
          <div key={index} className={`text-item ${index % 2 === 0 ? 'dark' : 'light'}`}>
            {t(item)}
          </div>
        ))}
      </div>
    </div>
  );
};

AdvantagesBox.propTypes = {
  isMirrored: PropTypes.bool,
  circleText: PropTypes.string.isRequired,
  textItems: PropTypes.arrayOf(PropTypes.string).isRequired,
};

AdvantagesBox.defaultProps = {
  isMirrored: false,
};

export default AdvantagesBox;
