/* eslint-disable max-len */
import React from 'react';
import TitleText from '../common/text_box/TitleText';
import Button from '../common/button/Button';
import FAQSection from '../common/FAQSeciton';
import InfoBox from '../common/text_box/InfoBox';
import InfoBoxDesktop from '../common/text_box/InfoBoxDesktop';
import InfoBoxInterFont from '../common/text_box/InfoBoxInterFont';
import AdvantagesBox from '../common/text_box/AdvantagesBox';
import './Battery.css';
import {scrollToElement} from '../../utils/Utils';
import {FAQS} from './FAQS';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from '../../hooks/ScreenSizeContext';

const Battery = () => {
  const {t} = useTranslation();
  const isDesktop = useScreenSize();

  const handleButtonClick = (id) => {
    scrollToElement(id, 62);
  };

  return (<div className="page-container">
    {isDesktop ?
    <><div className='battery-rows'>
      <div id="vertigo" className="battery-chapter-title" style={
        {alignSelf: 'start'}}>
        {t('battery.desktop.whyImportant')}
      </div>
      <div className='buttons-column'>
        <Button
          text={t('battery.householdElectricityStorage.title')}
          onClick={() => handleButtonClick('household')}
          style={{
            fontFamily: 'ClashGrotesk-Semibold',
            color: 'var(--Grey-300, #DBE5EC)',
            backgroundColor: 'var(--Accent-3, #869B55)',
            hoverBackgroundColor: 'var(--Accent-2, #597022)',
            width: '312px',
            whiteSpace: 'normal',
          }}
        />
        <Button
          text={t('battery.industrialElectricityStorage.title')}
          onClick={() => handleButtonClick('industry')}
          style={{
            fontFamily: 'ClashGrotesk-Semibold',
            color: 'var(--Grey-300, #DBE5EC)',
            width: '312px',
            whiteSpace: 'normal',
          }}
        />
        <Button
          text={t('battery.faq')}
          onClick={() => handleButtonClick('faq')}
          style={{
            fontFamily: 'ClashGrotesk-Semibold',
            color: 'var(--Grey-300, #DBE5EC)',
            backgroundColor: 'var(--Primary-2, #0D7C94)',
            hoverBackgroundColor: 'var(--Primary-1, #08547F)',
            width: '312px',
            whiteSpace: 'normal',
          }}
        />
      </div>
    </div>
    <div className='battery-rows'>
      <InfoBoxDesktop
        text={
          <span>{t('battery.description1.part1')}
            <span style={{fontWeight: 800, color: 'var(--Secondary-3, #EBCF6A)'}}>{t('battery.description1.part2')}</span>{t('battery.description1.part3')}
          </span>
        }
        style={{width: '100%', maxWidth: '539px'}}
      />
      <InfoBoxDesktop
        text={
          <span>
            <span style={{fontWeight: 800, color: 'var(--Secondary-3, #EBCF6A)'}}>{t('battery.description2.part1')}</span>{t('battery.description2.part2')}
            <span style={{fontWeight: 800, color: 'var(--Secondary-3, #EBCF6A)'}}>{t('battery.description2.part3')}</span>{t('battery.description2.part4')}<span style={{fontWeight: 800, color: 'var(--Secondary-3, #EBCF6A)'}}>{t('battery.description2.part5')}</span>{t('battery.description2.part6')}
          </span>
        }
        style={{width: '100%', maxWidth: '652px'}}
      />
    </div>
    <div id='household' className='battery-rows'>
      <InfoBoxInterFont
        title="battery.householdElectricityStorage.title"
        description="battery.householdElectricityStorage.description"
      />
      <AdvantagesBox
        circleText="battery.advantagesTitle"
        textItems={[
          'battery.advantages.chargeable',
          'battery.advantages.longLife',
          'battery.advantages.noWaste',
          'battery.advantages.europeanProduct',
        ]}
      />
    </div>
    <div id='industry' className='battery-rows'>
      <AdvantagesBox
        isMirrored="true"
        circleText="battery.advantagesTitle"
        textItems={[
          'battery.industrialAdvantages.compatible',
          'battery.industrialAdvantages.longLife',
          'battery.industrialAdvantages.suitable',
          'battery.industrialAdvantages.addressesPowerGaps',
        ]}
      />
      <InfoBoxInterFont
        title="battery.industrialElectricityStorage.title"
        description="battery.industrialElectricityStorage.description"
      />
    </div>
    </> :
    <>
      <div className="top-content">
        <TitleText text={t('battery.title')} style={
          {marginTop: '44vh', textAlign: 'left', height: '105px'}} />
        <Button
          text={t('battery.learnMore')}
          onClick={() => handleButtonClick('chapterTitle')}
          style={{marginBottom: '10vh', alignSelf: 'end'}}
        />
      </div>
      <div id="chapterTitle" className="battery-chapter-title" style={
        {alignSelf: 'center', textAlign: 'left', maxWidth: '345px'}}>
        {t('battery.whyImportant')}
      </div>
      <InfoBox
        text={
          <span>{t('battery.description1.part1')}
            <span style={{fontWeight: 800}}>{t('battery.description1.part2')}</span>{t('battery.description1.part3')}
          </span>
        }
        theme="light"
      />
      <InfoBox
        text={
          <span>
            <span style={{fontWeight: 800}}>{t('battery.description2.part1')}</span>{t('battery.description2.part2')}
            <span style={{fontWeight: 800}}>{t('battery.description2.part3')}</span>{t('battery.description2.part4')}<span style={{fontWeight: 800}}>{t('battery.description2.part5')}</span>{t('battery.description2.part6')}
          </span>
        }
        theme="light"
      />
      <InfoBoxInterFont
        title="battery.householdElectricityStorage.title"
        description="battery.householdElectricityStorage.description"
      />
      <AdvantagesBox
        circleText="battery.advantagesTitle"
        textItems={[
          'battery.advantages.chargeable',
          'battery.advantages.longLife',
          'battery.advantages.noWaste',
          'battery.advantages.europeanProduct',
        ]}
      />
      <InfoBoxInterFont
        title="battery.industrialElectricityStorage.title"
        description="battery.industrialElectricityStorage.description"
      />
      <AdvantagesBox
        isMirrored="true"
        circleText="battery.advantagesTitle"
        textItems={[
          'battery.industrialAdvantages.compatible',
          'battery.industrialAdvantages.longLife',
          'battery.industrialAdvantages.suitable',
          'battery.industrialAdvantages.addressesPowerGaps',
        ]}
      />
    </>}
    <div id='faq'>
      <FAQSection faqs={FAQS}/>
    </div>
  </div>);
};

export default Battery;
