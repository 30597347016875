import React from 'react';
import PropTypes from 'prop-types';
import './TextOnlySlideshow.css';
import './CustomScrollBar.css';
import {useTranslation} from 'react-i18next';

const TextOnlySlideshow = ({items}) => {
  const {t} = useTranslation();

  return (
    <div className="text-only-slideshow-container custom-scrollbar">
      {items.map((item, index) => (
        <div key={index} className="text-only-item">
          <h3 className="title">{t(item.title)}</h3>
          <p className="description">{t(item.description)}</p>
        </div>
      ))}
    </div>
  );
};

TextOnlySlideshow.propTypes = {
  items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })
  ).isRequired,
};

export default TextOnlySlideshow;
