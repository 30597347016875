const wind = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fwind_bg.webp?alt=media';
const windTurbin = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fwind_turbin_1.webp?alt=media';
const hybrid = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fhybrid_bg.webp?alt=media';
const battery = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fbattery_bg.webp?alt=media';
const plant1 = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fplant_1.webp?alt=media';
const plant2 = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fplant_2.webp?alt=media';

export const vertigoCards = [
  {
    image: wind,
    title: 'vertigo_cards.efficiency_title',
    description: 'vertigo_cards.efficiency_description',
  },
  {
    image: windTurbin,
    title: 'vertigo_cards.lifespan_title',
    description: 'vertigo_cards.lifespan_description',
  },
  {
    image: hybrid,
    title: 'vertigo_cards.silent_title',
    description: 'vertigo_cards.silent_description',
  },
  {
    image: battery,
    title: 'vertigo_cards.continuous_title',
    description: 'vertigo_cards.continuous_description',
  },
  {
    image: plant1,
    title: 'vertigo_cards.operable_title',
    description: 'vertigo_cards.operable_description',
  },
  {
    image: plant2,
    title: 'vertigo_cards.adaptable_title',
    description: 'vertigo_cards.adaptable_description',
  },
];

export const indiaCards = [
  {
    title: 'india_cards.versatility_title',
    description: 'india_cards.versatility_description',
  },
  {
    title: 'india_cards.visualImpact_title',
    description: 'india_cards.visualImpact_description',
  },
  {
    title: 'india_cards.installationCosts_title',
    description: 'india_cards.installationCosts_description',
  },
  {
    title: 'india_cards.reliability_title',
    description: 'india_cards.reliability_description',
  },
  {
    title: 'india_cards.decentralizedProduction_title',
    description: 'india_cards.decentralizedProduction_description',
  },
  {
    title: 'india_cards.lowMaintenance_title',
    description: 'india_cards.lowMaintenance_description',
  },
  {
    title: 'india_cards.lowWindSpeedPerformance_title',
    description: 'india_cards.lowWindSpeedPerformance_description',
  },
  {
    title: 'india_cards.visualAesthetics_title',
    description: 'india_cards.visualAesthetics_description',
  },
  {
    title: 'india_cards.savonius_title',
    description: 'india_cards.savonius_description',
  },
];
