import React from 'react';
import PropTypes from 'prop-types';
import './Slideshow.css';
import './CustomScrollBar.css';

const Slideshow = ({images}) => {
  return (
    <div className="slideshow-container custom-scrollbar">
      {images.map((image, index) => (
        <div key={index} className="image-container">
          <img src={image} alt="" />
        </div>
      ))}
    </div>
  );
};

Slideshow.propTypes = {
  images: PropTypes.array.isRequired,
};

export default Slideshow;
