import React from 'react';
import './TitleText.css';

const TitleText = ({text, style}) => {
  return (
    <div className='outlined-text' style={style}>
      {text}
    </div>
  );
};

export default TitleText;
