import React, {useState} from 'react';
import './FAQSection.css';
import downArrow from '../../assets/images/icons/down_arrow.svg';
import {useTranslation} from 'react-i18next';

const FAQItem = ({question, answer}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`faq-item ${isOpen ? 'open' : ''}`}>
      <div className="question-container">
        <p className="question">{question}</p>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`open-close-icon ${isOpen ? 'rotated' : ''}`}
        >
          <img src={downArrow} alt="Down arrow" />
        </button>
      </div>
      <hr />
      <p className="answer">{answer}</p>
    </div>
  );
};

const FAQSection = ({faqs}) => {
  const {t} = useTranslation();
  return (
    <div className="faq-section">
      <h2>{t('faqs.faq')}</h2>
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={t(faq.question)}
          answer={t(faq.answer)} />
      ))}
    </div>
  );
};

export default FAQSection;
