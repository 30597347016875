import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './Button.css';


const Button = ({text, style, onClick, disabled}) => {
  const [hover, setHover] = useState(false);

  const handleMouseOver = () => {
    if (!disabled) {
      setHover(true);
    }
  };

  const handleMouseOut = () => {
    if (!disabled) {
      setHover(false);
    }
  };

  const appliedStyle = style ? {
    ...style,
    backgroundColor: disabled ? '' : hover && style.hoverBackgroundColor ?
      style.hoverBackgroundColor : style.backgroundColor,
  } : {};

  return (
    <button
      className={`custom-button${disabled ? ' disabled' : ''}`}
      style={appliedStyle}
      onClick={disabled ? null : onClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
};

export default Button;
