import React from 'react';
import PropTypes from 'prop-types';
import './TextSlideshow.css';
import './CustomScrollBar.css';
import {useTranslation} from 'react-i18next';

const TextSlideshow = ({items}) => {
  const {t} = useTranslation();

  return (
    <div className="text-slideshow-container custom-scrollbar">
      {items.map((item, index) => (
        <div key={index} className="text-slideshow-item">
          <div className="image" style={{backgroundImage: `url(${item.image})`}}></div>
          <div className="title">{t(item.title)}</div>
          <div className="description">{t(item.description)}</div>
        </div>
      ))}
    </div>
  );
};

TextSlideshow.propTypes = {
  items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })
  ).isRequired,
};

export default TextSlideshow;
