export const FAQS = [
  {
    question: 'faqs.question1',
    answer: 'faqs.answer1',
  },
  {
    question: 'faqs.question2',
    answer: 'faqs.answer2',
  },
  {
    question: 'faqs.question3',
    answer: 'faqs.answer3',
  },
  {
    question: 'faqs.question4',
    answer: 'faqs.answer4',
  },
  {
    question: 'faqs.question5',
    answer: 'faqs.answer5',
  },
  {
    question: 'faqs.question6',
    answer: 'faqs.answer6',
  },
  {
    question: 'faqs.question7',
    answer: 'faqs.answer7',
  },
  {
    question: 'faqs.question8',
    answer: 'faqs.answer8',
  },
  {
    question: 'faqs.question9',
    answer: 'faqs.answer9',
  },
  {
    question: 'faqs.question10',
    answer: 'faqs.answer10',
  },
  {
    question: 'faqs.question11',
    answer: 'faqs.answer11',
  },
  {
    question: 'faqs.question12',
    answer: 'faqs.answer12',
  },
  {
    question: 'faqs.question13',
    answer: 'faqs.answer13',
  },
  {
    question: 'faqs.question14',
    answer: 'faqs.answer14',
  },
  {
    question: 'faqs.question15',
    answer: 'faqs.answer15',
  },
  {
    question: 'faqs.question16',
    answer: 'faqs.answer16',
  },
];
