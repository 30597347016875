import React, {useState} from 'react';
import Button from '../common/button/Button';
import Message from './Message';
import './Contact.css';
import phoneIcon from '../../assets/images/icons/phone_icon_grey700.svg';
import emailIcon from '../../assets/images/icons/mail_icon_grey_700.svg';
import {CSSTransition} from 'react-transition-group';
import {useTranslation} from 'react-i18next';
import {useScreenSize} from '../../hooks/ScreenSizeContext';

const mapLink = 'https://maps.app.goo.gl/U4VRet78wLeCQinR6';
const mapImage = 'https://firebasestorage.googleapis.com/v0/b/assal-green-energy.appspot.com/o/assets%2Fimages%2Fmap.webp?alt=media';

const Contact = () => {
  const {t} = useTranslation();
  const [showMessage, setShowMessage] = useState(false);
  const isDesktop = useScreenSize();

  const handleOpenMessage = () => {
    setShowMessage(true);
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  return (
    <div className="page-container">
      {isDesktop ?
        (<>
          <div className="contact-row">
            <div className="contact-left-column">
              <div className="contact-header">
                {t('contact.header')}
              </div>
              <Button text={t('contact.message_us')}
                onClick={handleOpenMessage} style={{
                  maxWidth: '222px',
                  fontSize: '32px',
                  margin: '50px 0px 90px 0px',
                }} />
              <div className="contact-info-box">
                <div className="info-row">
                  <img src={phoneIcon} alt="Phone Icon" />
                  <div className="contact-info-text">
                    {t('contact.desktop.call_us')}
                  </div>
                  <div className="info-detail">+36302967206</div>
                </div>
                <div className="info-row">
                  <img src={emailIcon} alt="Email Icon" />
                  <div className="contact-info-text">
                    {t('contact.desktop.email_us')}
                  </div>
                  <div className="info-detail">assal.sro2023@gmail.com</div>
                </div>
              </div>
            </div><a
              href={mapLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="map-container"
                style={{background: `url(${mapImage}) lightgray no-repeat center center / cover`}}
              >
                <p className="address-text">
                  {t('contact.address')}
                </p>
              </div>
            </a>
          </div>
        </>) :
        (<>
          <div className="top-content" style={{height: 'calc(65vh)',
            maxWidth: '344px'}}>
            <div className="contact-header">
              {t('contact.header')}
            </div>
            <div
              className="map-container"
              style={{background: `url(${mapImage}) lightgray no-repeat center center / cover`}}
            >
              <Button text={t('contact.message_us')}
                onClick={handleOpenMessage} style={{
                  position: 'absolute',
                  top: '15px',
                  right: '15px',
                }} />
              <a
                href={mapLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="address-text">
                  {t('contact.address')}
                </p>
              </a>
            </div>
          </div>
        </>)}
      <CSSTransition
        in={showMessage}
        timeout={500}
        classNames="fade"
        unmountOnExit
      >
        <div className="backdrop"></div>
      </CSSTransition>
      <CSSTransition
        in={showMessage}
        timeout={500}
        classNames="message"
        unmountOnExit
      >
        <Message onClose={handleCloseMessage} />
      </CSSTransition>
    </div>
  );
};

export default Contact;
