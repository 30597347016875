/**
 * Scrolls the webpage to a specific HTML element with a specified offset.
 *
 * This function calculates the absolute position of the given HTML element on the page,
 * considers the specified offset, and then smoothly scrolls the page to the resulting
 * position. It is useful for navigating to different sections of a webpage while considering
 * fixed headers or other elements that may obscure the view.
 *
 * @param {string} elementId - The ID of the HTML element to scroll to.
 * @param {number} offset - The number of pixels to offset the scroll. Positive values
 *                          will scroll the page upwards, while negative values will scroll downwards.
 *
 * @example
 * // Scrolls to the element with ID 'aboutUs', with a 62px offset upwards.
 * scrollToElement('aboutUs', 62);
 */
export const scrollToElement = (elementId, offset) => {
  const element = document.getElementById(elementId);
  const rect = element.getBoundingClientRect();
  const absoluteElementTop = rect.top + window.pageYOffset;
  const offsetPosition = absoluteElementTop - offset;
  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};
